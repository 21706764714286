
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/* Define palettes */
$md-primary: (
    50 : #e0edf6,
    100 : #b3d1e8,
    200 : #80b3d9,
    300 : #4d94ca,
    400 : #267dbe,
    500 : #0066b3,
    600 : #005eac,
    700 : #0053a3,
    800 : #00499a,
    900 : #00388b,
    A100 : #b7ceff,
    A200 : #84abff,
    A400 : #5188ff,
    A700 : #3777ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$md-secondary: (
    50 : #ffede0,
    100 : #ffd1b3,
    200 : #ffb380,
    300 : #ff944d,
    400 : #ff7d26,
    500 : #ff6600,
    600 : #ff5e00,
    700 : #ff5300,
    800 : #ff4900,
    900 : #ff3800,
    A100 : #ffffff,
    A200 : #fff4f2,
    A400 : #ffc9bf,
    A700 : #ffb4a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$frontend-primary: mat-palette($md-primary);
$frontend-accent: mat-palette($md-secondary);

// The warn palette is optional (defaults to red).
$frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
    warn: $frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($frontend-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.infowindow {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 40px;
  grid-auto-flow: row;
  grid-template-areas:
      ". .";
  justify-content: center; 
  align-content: center; 

  .content {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;

      strong {
        display: block;
        margin-bottom: 10px;
      }
  }
}

p {
  a {
    color: mat-color($frontend-primary, default);
  }
}